#nnn-pc {
  position: fixed;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 2px;
  top: 0px;
  z-index: 100;
  transition: 0.4s ease-in-out;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 0px 5px 10px rgb(88, 86, 86);
}
#kodo-pc {
  color: black;
  font-size: 1.9em;
  font-weight: 600;
  font-family: Courgette, cursive;
  text-shadow: 2px 2px 5px #ffffff;
  text-decoration: none;
}

#zyada-pc {
  font-size: 1.2em;
  font-family: "Roboto Slab", serif;
}
.custm-nav-kkk ul {
  list-style: none;
}

.custm-nav-kkk {
  text-align: center;
  display: inline-block;
}

.custm-nav-kkk a {
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;
  color: black;
  font-family: Courgette, cursive;
  text-shadow: none;
}

.custm-nav-kkk li:hover a {
  text-shadow: 3px 3px 7px #ffffff !important;
}

.custm-nav-kkk > ul {
  padding: 0px;
  margin: 0px;
  display: inline-block;
}

.custm-nav-kkk > ul > li {
  position: relative;
  float: left;
  width: auto;
  padding: 10px;
  margin: 0 5px;
  transition: 0.4s;
}
.shadow-kkk {
  color: black;
}
.active {
  text-shadow: 2px 3px 3px #4238cf !important;
}
