#Glass {
  position: fixed;
  width: 92vw;
  height: calc(92vh - 60px);
  top: calc(4vh + 60px);
  left: 4vw;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-around;
  font-family: "RocknRoll One", sans-serif !important;
  overflow-x: hidden;
  overflow-y: auto;
}

#Glass div {
  width: 300px;
  height: 200px;
  padding: 5px;
  margin: 5px;
  margin-bottom: 20px;
}

#Glass div center img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 32px 5px rgba(0, 0, 0, 0.37);
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

#Glass div center {
  color: white;
  text-shadow: 2px 2px 5px rgb(37, 37, 37);
  font-size: 1.4em;
}

@media only screen and (max-width: 600px) {
  #Glass div {
    width: 140px;
    height: 80px;
  }
  #Glass div center {
    color: white;
    text-shadow: 2px 2px 5px rgb(37, 37, 37);
    font-size: 1.1em;
  }
}

#Glass div:hover a,
#Glass image:hover a,
#Glass span:hover a {
  cursor: pointer;
  text-decoration: none !important;
}
