#c-f {
  background: #262626;
  width: 100%;
  position: absolute;
  left: 0;
}
#fsocial div a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #171515;
  border-radius: 50%;
  font-size: 25px;
  transition: 0.5s;
}
#fsocial {
  margin: 70px 8% 70px 10%;
}
@media only screen and (max-width: 600px) {
  #fsocial div a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    box-shadow: 0 0 10px;
  }
}
@media only screen and (max-width: 500px) {
  #fsocial div a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    box-shadow: 0 0 10px;
  }
  #fsocial {
    margin: 30px 5% 30px 5%;
  }
}
#yakamm {
  color: #fff;
  box-shadow: 0 0 20px #fff;
}
#dwamm {
  color: #1da1f2;
  box-shadow: 0 0 20px #1da1f2;
}
#seyamm {
  color: #087bea;
  box-shadow: 0 0 20px #087bea;
}
#chwaramm {
  color: #ef4d5d;
  box-shadow: 0 0 20px #ef4d5d;
}
#penjamm {
  color: red;
  box-shadow: 0 0 20px red;
}
#shashamm {
  color: #fffc00;
  box-shadow: 0 0 20px #fffc00;
}
.skills {
  max-width: 100%;
  margin: 20px auto;
  letter-spacing: 2px;
  font-family: Lato, Arial, Helvetica, sans-serif;
  text-align: center;
}
.skills .hr-text {
  font-size: 12px;
  position: relative;
  border: 0;
  height: 1.2em;
}
.skills .hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #b2b2b2, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.skills .hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: #000;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #fff;
  background-color: #262626;
}
