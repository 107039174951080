.super_background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0px;
  left: 0px;
}
