#present {
  position: fixed;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-family: "RocknRoll One", sans-serif !important;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
  font-family: Poppins, sans-serif;
  text-shadow: 2px 2px 4px rgb(82, 82, 82);
  padding-bottom: 10px;
  font-size: 1.3em;
}
#xware {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
}
#present button {
  outline: none;
  border: none;
  background-color: #157efb;
  border-radius: 100px;
  font-size: 18px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 30px;
  margin-right: 30px;
  color: wheat;
  box-shadow: 1px 1px 5px rgb(36, 36, 36);
}

#present button a {
  color: wheat;
}
#present button:hover a,
#present button:hover a:hover {
  text-decoration: none;
}
