.working {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 20px;
  background-color: #c4d6e2;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .avaiables {
    font-size: 2em;
  }
}
@media only screen and (max-width: 564px) {
  .avaiables {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 443px) {
  .avaiables {
    font-size: 1em;
  }
}
#wrk {
  width: 45%;
}
