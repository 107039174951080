#ifull1 {
  background-color: #eee;
  position: absolute;
  top: 99%;
}
#bit-look {
  position: absolute;
  height: 100px;
  width: 150px;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
}
#hr1look {
  margin: 0;
  padding: 0;
  height: 1vh;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(151, 189, 207, 0.5);
}
#ifull2 {
  background: #eee;
  width: 100%;
  height: 100%;
}
#welcomem {
  word-spacing: 4px;
  font-size: 2.9em;
  font-family: Courgette, cursive;
  margin-top: 20px;
}
#welcomep {
  font-size: 22px;
  text-justify: inter-word;
}
#ifull3 {
  margin-top: 50px;
}
#fhr {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}
#fhr:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: #eee;
}
.connn img {
  height: 30vh;
}
.connn {
  margin-bottom: 60px;
}
.connn img p {
  font-weight: 900;
}
@media only screen and (max-width: 998px) {
  #welcomem {
    font-size: 0;
  }
  #welcomep {
    font-size: 1.76vw;
  }
}
@media screen and (max-width: 992px) {
  #welcomem {
    font-size: 40px;
  }
}
@media only screen and (max-width: 770px) {
  #welcomem {
    font-size: 30px;
  }
  #welcomep {
    font-size: 15px;
  }
}
@media screen and (max-width: 550px) {
  #welcomem {
    font-size: 20px;
  }
}
@media screen and (max-width: 420px) {
  #welcomem {
    margin-top: 0;
    font-size: 15px;
    font-weight: 600;
  }
}
@media screen and (max-width: 335px) {
  #welcomem {
    font-size: 12px;
  }
}
@media screen and (max-width: 289px) {
  #welcomem {
    font-size: 10px;
  }
}
@media screen and (max-width: 259px) {
  #welcomem {
    font-size: 7px;
  }
}
@media only screen and (min-width: 900px) {
  #seeting {
    height: 65vh;
  }
}
