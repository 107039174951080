#welcome_div {
  background-image: url(../../asset/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 1%;
}

#nameanime {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#nameanime > div {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#nameanime > div #tttitle {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
#nameanime > div #tttitle .bbblock {
  width: 0%;
  height: inherit;
  background: #ffb510;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
#nameanime > div #tttitle h1 {
  background-color: none;
  color: #fff;
  font-size: 45px;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 3.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  font-family: Courgette, cursive;
  text-shadow: 2px 2px 3px #525050;
  font-family: Poppins;
  margin-right: 10px;
}
#nameanime > div #tttitle h1 span {
  width: 0;
  height: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffb510;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 4s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
  text-shadow: 2px 2px 3px #525050;
}
#nameanime > div #rrrole {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-left: 2px;
  margin-top: -5px;
}
#nameanime > div #rrrole .bbblock {
  width: 0%;
  height: inherit;
  background: #e91e63;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 4s;
  display: flex;
}
#nameanime > div #rrrole p {
  animation: secFadeIn 2s forwards;
  animation-delay: 5.2s;
  opacity: 0;
  font-weight: 600;
  font-family: Lato;
  color: #752dc7;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding-top: 10px;
  text-shadow: 2px 2px 3px #525050;
}
@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0;
    height: 0;
    background: #e9d856;
    border: 0 solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0 solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
#con_info {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  margin-bottom: 30px;
  background: -o-radial-gradient(
    0% 0%,
    100% 100%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(10px);
  -webkit-box-shadow: 0px 0px 12px rgb(82, 79, 79);
  box-shadow: 0px 0px 15px rgb(65, 63, 63);
  transition: 0.5s;
}
#con_info:hover {
  cursor: pointer !important;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: -o-radial-gradient(
    0% 0%,
    100% 100%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-box-shadow: 0px 0px 16px rgb(82, 79, 79);
  box-shadow: 0px 0px 16px rgb(117, 117, 117);
}
#a3a {
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;
  letter-spacing: 2px;
  text-shadow: 3px 3px 7px #000;
  text-decoration: none;
  font-family: Courgette, cursive;
}
