.mobile {
  position: fixed;
  height: auto;
  left: 0px;
  top: -55px;
  z-index: 20;
  width: 100%;
  display: block;
  transition: top 0.2s ease-in-out;
}

.classynav {
  position: fixed;
  background-size: cover;
  background-position: center;
  left: 2vw;
  width: 110px;
  height: 150px;
}

#cn-d {
  margin-left: 12%;
  margin-top: 56%;
}
.menu {
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
}
.closed a,
.closed header {
  transform: translateY(calc(var(--top) * -1)) scaleY(0.1) scaleX(0.2);
  transition-delay: var(--delay-in);
  color: transparent;
}
.closed footer button {
  transition-delay: 0s;
  transform: scaleY(0.7) scaleX(0.2);
}
#nav {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  color: #333;
  font-size: 100%;
  line-height: 1.5;
  --duration: 0.3s;
  --easing: ease-in-out;
  position: relative;
  width: 220px;
  margin: 20px;
}
#nav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#nav li {
  margin: -4px 0 0 0;
}
#nav a:hover {
  background: #157efb;
}
#nav .sub-menu a {
  font-size: 0.9em;
  color: #6666;
  border-left: 2em solid #fff;
  padding: 0.75em;
  background: linear-gradient(to right, #ddd 2px, #fff 2px);
}
#nav .sub-menu a:hover {
  background: linear-gradient(to right, #ddd 2px, #157efb 2px);
}
s #nav header span {
  border: none;
  background: 0 0;
  font-size: 1.5em;
  padding: 0;
  cursor: pointer;
  line-height: 1;
  float: right;
}
#nav footer button {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  padding: calc(1em - 2px);
  width: 100%;
  transform-origin: 0 0;
  transition: transform var(--duration) var(--easing);
  transition-delay: calc(var(--duration) + (0.1s * (var(--count) / 2)));
  cursor: pointer;
  outline: 0;
  background: #cdcdcd;
  opacity: 0;
}
.n-h {
  font-weight: 600;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  transform-origin: 0 0;
  transition: transform var(--duration) var(--easing),
    color var(--duration) var(--easing);
  transition-delay: var(--delay-out);
  border-radius: 4px;
  padding: 1em 1.52em;
}
.mm {
  display: block;
  text-decoration: none;
  background: #fff;
  transform-origin: 0 0;
  transition: transform var(--duration) var(--easing),
    color var(--duration) var(--easing);
  transition-delay: var(--delay-out);
  padding: 1em 1.52em;
}
.mmm:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fixx {
  color: #333;
}
.fixx:hover {
  color: inherit;
  text-decoration: none;
}
#nav a:hover {
  transition: 0s !important;
  background: rgb(235, 235, 235) !important;
  text-decoration: none;
  color: black !important;
}
.noshadow {
  text-shadow: none !important;
}

.menu-active {
  background: #157efb;
}

/* .closed{
  width: 220px !important;
  height: 100px !important;
  overflow: hidden;
} */

/* .closed ol{
  position: relative; 
  z-index: -1;
} */

/* .closed .menu-item{
  position: relative;
} */